<script>
import { onMounted, onUnmounted, watch, ref, reactive } from 'vue'
import { http } from '@/http'
import { setScrollTop, getScrollTop, regFenToYuan } from '@/utils'
import { useRouter, useRoute } from 'vue-router'
import { needLoading, loading } from '@/reactive'
import useScrollData from '@/reactive/useScrollData'

export default {
  setup() {
   
    let route = useRoute()
    let router = useRouter()
    let { pageNum, tabType, getData, handleScroll, handleWatchData, dataList, dataListTemp, scrollElement } = useScrollData()
    let userId = JSON.parse(window.localStorage.getItem('userAuth')).id
    tabType.value = route.query.tab === 'cash' ? '' :'UNCLEARING'

    function getApiUrl(tabType) {
      return tabType ? 
        '/?cmd=com.xqxc.api.pigear.biz.settle.AccountDetailService.brokerExpectList' : 
        '/?cmd=com.xqxc.api.pigear.pay.BrokerWithdrawalService.withdrawalList'
    }

    function handleClickJumpPage(id) {
      if(!tabType.value) {
        router.push(`/settlement/cash-detail?id=${id}`)
      }
    }
    
    handleWatchData(() => tabType.value, (type) => {
      getData(getApiUrl(type), {
        clearingType: type || undefined,
        outUserId: type ? userId : undefined
      })
    })
    
    onMounted(() => {
      getData(getApiUrl(tabType.value), {
        clearingType: tabType.value,
        outUserId: tabType.value ? userId : undefined
      })
      handleScroll(() => {
        getData(getApiUrl(tabType.value), {
          clearingType: tabType.value || undefined,
          outUserId: tabType.value ? userId : undefined
        })
      })
    })


    return {
      tabType,
      loading,
      dataList, 
      dataListTemp,
      scrollElement,
      regFenToYuan,
      handleClickJumpPage
    }
  }

}
</script>
<template>
  <div style="padding-bottom:10px">
    <!-- <div class="page-title">
      <h3 >邀请记录</h3>
    </div> -->
    <div style=" position:relative; height: 45px; margin-bottom:12px">
      <div class="tab">
        <span @click="tabType = 'UNCLEARING'" :class="{active: tabType === 'UNCLEARING'}" >待结算明细</span>
        <span @click="tabType = 'CLEARING'" :class="{active: tabType === 'CLEARING'}">已结算明细</span>
        <span @click="tabType = ''" :class="{active: tabType === ''}">支取明细</span>
      </div>
    </div>

    <div class="r-box" ref="scrollElement">
      <ul v-if="dataList.length">
        <li class="income-item" v-for="(item, idx) in dataList" :key="idx" @click="handleClickJumpPage(item.id)">
          <template v-if="tabType">
            <div class="cell">
              <p>{{item.accountAliasNo}}【{{item.source}}】</p>
              <p>{{item.transTime}}</p>
              <p>{{item.orderNo}}</p>
            </div>
            <div class="cell" style="color:#ec0909">{{item.changeBalanceValue}}</div>
          </template>
          <template v-else>
            <div class="cell">
              <p>{{regFenToYuan(item.withdrawalAmount)}}</p>
              <p>{{item.bankName}}({{item.bankNo}})</p>
            </div>
            <div class="cell" style="color:#ec0909">
              <p>{{item.status}}</p>
              <p>{{item.gmtCreate}}</p>
            </div>
          </template>
        </li>
      </ul>
      <p v-if="loading === false && !dataListTemp.length" style="text-align: center; font-size:.28rem">暂无数据 :(</p>
    </div>
  </div>

</template>

<style scoped>
  .top-bg {overflow: hidden; padding: 0 14px;  margin-bottom: -4rem; color: #fff; height: 130.24rem; background-size: 100% 100%; background-image:url('~@/assets/images/bg-rd-top.png') }
  .top-bg p { font-size: 0.24rem }
  .price { margin-top: 1.53125rem; display: inline-block; font-weight: 700; font-size:1.6rem }
  
  .tab {  table-layout: fixed;padding:15px 10px;  background: #fff;position: fixed; top: 0; display:table; width:100% }
  .tab span { display:table-cell; font-size: .28rem; text-align: center}
  .tab span.active { color: #00abeb }
  .income-item { display: table; width: 100%; box-sizing: border-box;font-size: 0.28rem; padding-top: 15px; border-bottom:1px solid #f1f1f1 }
  .income-item:last-child { margin-bottom:.3rem }
  .income-item span { display: inline-block;color:#999 }
  .income-item .cell { display:table-cell }
  .income-item .cell:last-child {color: #999; text-align: right; font-size: 0.24rem;vertical-align:middle }
  .income-item p { margin-bottom:14px }
  .income-item p:last-child {  color: #999;margin-bottom:14px }
</style>